/* Add this CSS in your stylesheet */
@media (max-width: 768px) {
  .carousel-caption-test h1 {
   margin: 0 !important;
   padding: 0 !important;
    font-size: 1rem !important; /* Adjust heading size */
  }

  .carousel-caption-test p {
    font-size: 1rem !important; 
    margin: 0 !important;
   padding: 0 !important;
  }

  .carousel-caption-test .btn {
    padding: 10px 20px !important; /* Adjust button padding */
  }
  .carousel-button{
    display: none !important;
  }
}
