@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css");

.register-page {
  display: flex; /* Flexbox to center content */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  min-height: 100vh; /* Full viewport height */
  background-color: #f0f0f0; /* Background color for better contrast */
  padding: 20px; /* Padding for small screens */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.register-container {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.register-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.register-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column; /* Ensure label and input are stacked */
}

.input-group label {
  margin-bottom: 5px;
  color: #555;
}

.input-group input {
  width: 100%;
  padding: 10px;
  padding-right: 40px; /* Space for the toggle icon if needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px; /* Added margin for better spacing */
}

.password-wrapper {
  display: flex; /* Keep input and icon inline */
  align-items: center; /* Center vertically */
  position: relative;
}

.password-wrapper input {
  flex: 1; /* Allow the input to take available width */
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #777;
}

.toggle-password:hover {
  color: #333;
}

.register-button {
  background-color: #225683;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.register-button-disable {
  background-color: #b0b0b0; /* Light gray background */
  color: #fff; /* White text color */
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: not-allowed; /* Disabled cursor */
  opacity: 0.6; /* Reduced opacity */
  transition: background-color 0.3s, opacity 0.3s; /* Smooth transition */
}

.register-button:hover {
  background-color: #2b9bff;
}

.register-links {
  margin-top: 20px;
  text-align: center;
}

.register-links a {
  color: #225683;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s;
}

.register-links a:hover {
  color: #2b9bff;
}
