.whatsapp-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: white;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  cursor: pointer;
  transition: all 0.3s ease;
}

.whatsapp-float:hover {
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
}

.whatsapp-icon {
  width: 100%;
  height: 100%;
  padding: 15px;
}
